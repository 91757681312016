// Section subtitle component
// --------------------------

.section-subtitle {
  opacity: 0;
  transform: translate3d(0.618rem, 0, 0);
  transform-origin: bottom;
  transition: all 0.382s ease-out;

  &.is-subtitle_shown {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Remove breaks to keep text in line.
.section-subtitle h2 > br,
.blog-intro h2 > br {
  @media (max-width: $max-xsmall) {
    display: none;
  }
}
