// Typography
// ----------

h1 {
  color: $light-grey;
  font-size: 1rem;
  font-weight: $weight-medium;
  text-transform: uppercase;
}

h2, h4 {
  font-size: 1.618rem;
}

h3, h5 {
  font-size: 0.78rem;
  font-weight: $weight-medium;
  text-transform: uppercase;
}

b {
  border-bottom: 2px solid currentColor;
  font-weight: $weight-normal;

  @media (max-width: $max-xsmall) {
    border-width: 1px;
  }
}

.body_blog b {
  border: none;
  font-weight: $weight-bold;
}
