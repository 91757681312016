// Hiring layout
// -------------

.hiring {
  margin: 11.089rem auto 0;
  max-width: 75.999rem;
}

// [1] Set a right padding to avoid an overlapping of main content and aside
//     buttons.
.hiring-positions {
  margin-right: 17.942rem;
  max-width: 46.971rem;
  padding-right: 1.618rem; // [1]

  @media (max-width: $max-small) {
    margin-right: 0;
    max-width: 76.999vw;
    padding-right: 0;
  }

  @media (max-width: $max-xsmall) {
    max-width: none;
  }
}

.hiring-intro p {
  color: $light-grey;
  margin-top: 1rem;

  &:first-child {
    margin-top: 1.618rem;
  }
}

.position {
  margin-top: 4.236rem;
  min-width: 17.942rem;
  padding-left: 4.236rem;

  @media (max-width: $max-small) {
    padding-left: 2.618rem;
    min-width: 0;
    width: 100%;
  }

  @media (max-width: $max-xsmall) {
    padding-left: 0;
  }
}

.position-container {
  margin-left: 1.618rem;

  @media (max-width: $max-xsmall) {
    margin-left: 0;
  }
}

.position-container > * {
  color: $light-grey;
  margin-top: 1rem;
}

// Styles for bullet lists.
.position-container > ul {
  list-style-type: circle;
  padding: 0 0 0 1rem;
}

.position-field {
  margin-top: 1.618rem;
}

.position-link {
  margin-top: 2.618rem;
}

.hiring-outro {
  margin-top: 6.854rem;
}

.position-selector {
  position: absolute;
  right: 6.854vw;
  top: 17.942rem;

  @media (max-width: $max-small) {
    display: none;
  }

  // Remove all transitions after entrance transition is executed. This way the
  // element is fixed without any transition.
  &.is-transition_done {
    transition: none;
  }

  // [1] Fix the element to the viewport.
  // [2] Set the element's position when it is fixed to the viewport.
  &.is-fixed {
    position: fixed; // [1]
    top: 17.942%; // [2]
  }
}

.position-selector-container {
  margin: 1.618rem 0 0 2.618rem;

  & > * + * {
    margin-top: 1.618rem;
  }
}

.position-selector-title {
  cursor: pointer;
  max-width: 11.036rem;
  position: relative;
  transition: text-shadow 0.262s;

  &:hover {
    text-shadow: $text-glow;
  }
}
