// Variables
// ---------

// Typefaces
$aktiv: 'aktiv-grotesk', sans-serif;

// Typography
$weight-normal: 300;
$weight-medium: 400;
$weight-bold: 500;

// Z-indexes
$z-header-large: 100;
$z-header-small: 1000;
$z-nav-large: 1000;
$z-nav-small: 100;

// Gradients
$gradient-blue: linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%);
$gradient-green: linear-gradient(135deg, #24BF89 0%, #4CA2CD 100%);
$gradient-fuchsia: linear-gradient(135deg, #FC6767 0%, #EC008C 100%);
$gradient-orange: linear-gradient(135deg, #F7B733 0%, #FC6119 100%);

// Various
$image-shadow: 0px 16px 38px rgba(0, 0, 0, 0.46);
$image-light-shadow: 0px 10px 23px rgba(0, 0, 0, 0.28);
$text-glow: 0px 0px 10px transparentize($green, 0.05);
$box-glow: 0px 0px 10px 0px transparentize($green, 0.05);
$filter-glow: drop-shadow(0px 0px 10px transparentize($green, 0.05));
