// Contacts layout
// ---------------

.contacts {
  margin: 11.089rem auto 0;
  max-width: 75.999rem;

  @media #{$small-landscape} {
    margin: 6.854rem auto 0;
  }
}

.contacts-container {
  display: flex;
  justify-content: space-around;

  @media (max-width: $max-small) {
    flex-direction: column;
  }
}

.contacts-intro {
  @media (max-width: $max-small) {
    margin-top: 4.236rem;
    order: 1;
  }
}

.contacts-intro > p + p {
  margin-top: 1rem;
}

.contacts-intro > p:last-child {
  margin-top: 4.236rem;
}

.contacts-intro,
.contacts-info {
  flex: 1;
  max-width: 29.03rem;
}

.contacts-info {
  margin: 0 0 0 2.618rem;
  transition-delay: 0.236s;

  @media (max-width: $max-small) {
    margin: 0;
  }
}

.contacts-info-links-mail_info,
.contacts-info-links-mail_jobs, {
  font-size: 1.618rem;
}

.contacts-info-links-mail_jobs {
  margin-top: 1.618rem;
}

.contacts-info-links-mail_info a,
.contacts-info-links-mail_jobs a {
  font-weight: bold;
}

.contacts-info-address {
  color: $light-grey;
  margin-top: 4.236rem;
}

.contacts-info-address-phone,
.contacts-info-address-vat {
  margin-top: 1rem;
}
