// Scroll down advisor component
// -----------------------------

// Place the scrolldown icon to the bottom center of the screen
.scrolldown {
  bottom: 1rem;
  left: 1.618rem;
  position: absolute;
  right: 1.618rem;
  text-align: center;
  transition: opacity 0.382s;

  &.has-scrolled {
    opacity: 0;
  }
}
