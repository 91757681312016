// Blog page
// ---------

.blog {
  margin-top: 17.942rem;
  max-width: calc(76.999rem + 11.089rem);

  @media (max-width: $max-xsmall) {
    margin-top: 11.089rem;
  }
}

.blog-intro {
  font-size: 1.618rem;
  max-width: 29.03rem;
}

.blog-container {
  margin: 6.854rem auto 0;
  max-width: 75.999rem;

  @media (max-width: $max-small) {
    margin-top: 4.236rem;
  }
}

.post_preview {
  align-items: center;
  display: flex;

  @media (max-width: $max-small) {
    flex-direction: column;
  }

  & + .post_preview {
    margin-top: 11.089rem;

    @media (max-width: $max-small) {
      margin-top: 6.854rem;
    }
  }
}

.post_preview-image {
  flex: 2;
}

.post_preview-image-figure {
  opacity: 0;
  position: relative;
  transition: opacity 0.618s ease-out;

  // Create a div which overlaps the image needed to do the sliding transition.
  &::after {
    background-color: $white;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 0.382s ease-out 0.382s;

    @media (max-width: $max-xsmall) {
      transition-delay: 0;
    }
  }
}


.post_preview.is-element_revealed .post_preview-image-figure {
  opacity: 1;

  // [1] Sliding transition start from the left
  &::after {
    transform: scale3d(0, 1, 1);
    transform-origin: left; // [1]
  }
}

.post_preview-info {
  flex: 1;
  margin: 0 0 0 2.618rem;

  @media (max-width: $max-small) {
    margin: 2.618rem 0 0 0;
    width: 100%;
  }

  @media (max-width: $max-small) {
    margin: 1.618rem 0 0 0;
  }
}

.post_preview.is-element_revealed .post_preview-info {
  opacity: 1;
  transform: translateZ(0);
}

.post_preview-info-metadata {
  color: $dark-grey;
  margin-top: 1rem;
}

.post_preview-info-metadata-author {
  font-weight: bold;
}

.post_preview-info-description {
  color: $dark-grey;
  margin-top: 1.618rem;
}

.post_preview-info-link {
  margin-top: 1.618rem;
}
