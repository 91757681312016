// Splash layout
// -------------

.splash {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: auto;
  max-width: calc(76.999rem + 11.089rem);
  padding-bottom: 1rem;
  position: relative;
}

// Position the canvas child to the center.
.splash-3dmodels {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.splash-3dmodels > canvas {
  cursor: -webkit-grab;
}

// [1] Apply the filter to the SVG's parent instead of applying it directly to
//     the SVG, in order to avoid a bug on iOS.
//     See https://stackoverflow.com/questions/36705323/why-is-filterdrop-shadow-causing-my-svg-to-disappear-in-safari
.splash-logo {
  align-self: center;
  filter: drop-shadow(0px 0px 3px transparentize($white, 0.05)); // [1]
  margin-top: 29.03vh;
  position: relative;
  width: 29.03rem;

  @media (max-width: $max-xsmall) {
    width: 76.999vw;
  }

  @media #{$small-landscape} {
    margin-top: 17.942vh;
    width: 46.971vw;
  }
}

.splash-logo > svg {
  width: 100%;
}

// [1] Margin-top: auto is needed to let the payoff take the entire space after
//     the logo, this way it is positioned to the bottom.
.payoff {
  font-size: 1.618rem;
  margin: auto 0 4.236rem 0; // [1]
  position: relative;

  @media (max-width: $max-xsmall) {
    font-size: 1rem;
    font-weight: $weight-medium;
    max-width: 76.999vw;
    text-align: center;
    margin: 1rem auto 4.236rem auto;
  }

  @media #{$small-landscape} {
    font-size: 1rem;
    font-weight: $weight-medium;
    max-width: 76.999vw;
    text-align: center;
    margin: 1rem auto 4.236rem auto;
  }
}

// [1] Align paragraphs on smartphone devices.
.payoff > p {
  @media (max-width: $max-xsmall) {
    display: inline; // [1]
  }

  // [1] Add a space to all inlined paragraphs, simulating the default word
  //     spacing.
  & + p {
    @media (max-width: $max-xsmall) {
      margin-left: 0.236rem; // [1]
    }
  }
}
