// Normalize.css
@import "npm:normalize.css";

h1, h2, h3, h4, h5, p, figure, blockquote {
  margin: 0;
}

h1, h2, h3, h4, h5 {
  font-weight: $weight-normal;
}

a {
  color: inherit;
  text-decoration: none;

  @include event {
    color: inherit;
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 */
::selection {
    text-shadow: none;
}
