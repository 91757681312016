// Link component
// --------------

.button {
  display: inline-block;
  font-weight: $weight-bold;
  position: relative;

  // Create a line above the text
  &::before {
    border-top: 3px solid $white;
    content: '';
    left: 0;
    position: absolute;
    top: -0.382rem;
    width: 100%;
  }
}

// Add a green glow around text and line when hover event is fired.
.glow_link {
  transition: all 0.262s ease-out;

  &::before {
    transition: box-shadow 0.236s ease-out;
  }

  &:hover {
    color: $white;
    text-shadow: $text-glow;

    &::before {
      box-shadow: $box-glow;
    }
  }
}

// Line is hidden by default, it appears when hover event is fired
.button.is-line_appearing {
  &::before {
    transition: transform 0.236s ease-out;
    transform: scale3d(0, 1, 1);
  }

  &:hover {
    &::before {
      transform: scale3d(1, 1, 1);
    }
  }
}

// Change button color into blog templates
.body_blog .button {
  transition: all 0.236s ease-out;

  &::before {
    border-color: $black;
    transition: all 0.236s ease-out;
  }

  &:hover {
    color: $green;

    &::before {
      border-color: $green;
    }
  }
}

// Disable the glow into blog templates
.body_blog .glow_link {
  &:hover {
    text-shadow: none;

    &::before {
      box-shadow: none;
    }
  }
}
