// Bio layout
// ----------

.bio {
  margin: 11.089rem auto 0;
  max-width: 75.999rem;
}

.bio-container {
  display: flex;

  @media (max-width: $max-medium) {
    flex-direction: column;
  }
}

.bio-description {
  flex: 1;
  width: 29.03rem;

  @media (max-width: $max-medium) {
    width: 100%;
  }
}

.bio-description-paragraph {
  color: $light-grey;
  margin: 2.618rem 0 0 4.236rem;
  transition-delay: 0.236s;

  @media (max-width: $max-medium) {
    margin: 2.618rem 0 0 0;
  }
}

.bio-picture {
  flex: 1;
  margin-left: 4.236rem;
  opacity: 0;
  position: relative;
  transition: opacity 0.618s ease-out;
  width: 46.971rem;

  // Create a div which overlaps the image needed to do the sliding transition.
  &::after {
    background-color: $black;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale3d(1, 1, 1);
    transform-origin: left;
    transition: transform 0.382s ease-out 0.382s;
  }

  &.is-element_revealed {
    opacity: 1;

    &::after {
      transform: scale3d(0, 1, 1);
    }
  }

  @media (max-width: $max-medium) {
    margin: 1.618rem 0 0 0;
    width: 100%;
  }
}
