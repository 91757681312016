// Blog post page
// --------------

.post {
  margin: auto;
  max-width: 46.971rem;
  margin-top: 17.942rem;

  @media (max-width: $max-medium) {
    margin-top: 11.089rem;
  }

  @media #{$small-landscape} {
    margin-top: 6.854rem;
  }
}

.post-metadata {
  align-items: center;
  color: $dark-grey;
  display: flex;
}

// [1] Set a min-width to avoid that the image stretches when resizing the
//     browser. Its value has to be the same as the width.
.post-metadata-author_picture {
  border-radius: 50%;
  height: 2.618rem;
  min-width: 2.618rem; // [1]
  overflow: hidden;
  width: 2.618rem;
}

.post-metadata-author,
.related_posts-post-metadata-author {
  font-weight: $weight-bold;
}

.post-metadata-author_picture > img {
  box-shadow: none;
  min-width: 100%;
}

// Wrap feature is needed to wrap the entire date when resizing the browser
// instead of split it in two pieces.
.post-metadata-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
}

// Add horizontal margins to the dot instead of setting a margin-left to every
// metadata item, this way when the date wraps it's aligned to the left.
.post-metadata-container span:nth-child(2) {
  margin: 0 0.382rem;
}

.post-title {
  margin-top: 2.618rem;
}

.post-intro {
  color: $dark-grey;
  margin-top: 1.618rem;
}

.post-intro_picture,
.post-picture {
  box-shadow: none;
  margin-top: 2.618rem;
}

.post-intro_picture > img,
.post-picture > img {
  width: 100%;
}

.post-block_title {
  color: $dark-grey;
  margin-top: 2.618rem;
}

.post-block_subtitle {
  color: $dark-grey;
  font-weight: bold;
  margin-top: 1.618rem;
}

.post-paragraph {
  margin-top: 1.618rem;
}

// Select every paragraph immediately after an image
.post-picture + .post-paragraph {
  margin-top: 2.618rem
}

.post-paragraph > p > a {
  color: $dark-grey;
  text-decoration: underline;
}

.post-picture-caption {
  color: $dark-grey;
  font-size: 0.78rem;
  font-style: italic;
  margin-top: 1rem;
  text-align: center;
}

.post-blockquote,
.post-code-snippet {
  color: $dark-grey;
  margin: 2.618rem 0 0 4.236rem;

  @media (max-width: $max-xsmall) {
    margin: 2.618rem 0 0 1.618rem;
  }
}

.post-blockquote {
  color: $dark-grey;
}

.post-video {
  margin-top: 2.618rem;
}

// [1] Remove a default padding bottom which causes an unneeded space
.post-video > div {
  display: flex;
  justify-content: center;
  padding-bottom: 0 !important; // [1]
}

.post-code-snippet .code {
  width: 100%;
}

.related_posts {
  border-top: 2px solid $dark-grey;
  display: flex;
  margin: 6.854rem auto 0;
  max-width: 76.999rem;
  padding-top: 4.236rem;

  @media (max-width: $max-small) {
    flex-direction: column;
  }

  & > * + * {
    margin: 0 0 0 1.618rem;

    @media (max-width: $max-small) {
      margin: 4.236rem 0 0 0;
    }
  }
}

.related_posts-post {
  width: 33%;

  @media (max-width: $max-small) {
    width: 100%;
  }
}

.related_posts-post-picture > img {
  box-shadow: $image-light-shadow;
  width: 100%;
}

.related_posts-post-title {
  margin-top: 1rem;
}

.related_posts-post-metadata {
  align-items: center;
  color: $dark-grey;
  display: flex;
  margin-top: 0.618rem;
}

.related_posts-post-description {
  color: $dark-grey;
  margin-top: 1rem;
}

.related_posts-post-link {
  color: $dark-grey;
  margin-top: 1.618rem;
}
