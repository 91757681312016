// Expertise section
// -----------------

.expertise {
  margin: 11.089rem auto 0;
  max-width: 75.999rem;

  @media #{$small-landscape} {
    margin: 6.854rem auto 0;
  }
}

.expertise-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: $max-medium) {
    flex-direction: column;
  }
}

.expertise-description {
  max-width: 29.03rem;
}

.expertise-description p + p {
  margin-top: 1rem;
}

.expertise-description-text {
  color: $light-grey;
  margin: 2.618rem 0 0 4.236rem;
}

.expertise-opensource_case {
  margin-top: 4.236rem;
  max-width: 29.03rem;

  @media (max-width: $max-medium) {
    margin: 4.236rem auto 0;
  }

  @media (max-width: $max-small) {
    max-width: none;
  }
}

.expertise-opensource_case > .work {
  padding-right: 0;

  @media (max-width: $max-medium) {
    padding: 0;
  }
}

// Add a left alignment to the open source case's elements
.expertise-opensource_case > .work > .work-info {
  align-items: flex-start;
  padding: 0;
  text-align: left;
  transform: translate3d(1.618rem, 0, 0);
}

.expertise-opensource_case > .work.is-element_revealed > .work-info {
  transform: translateZ(0);
}

.stack {
  margin: 6.854rem auto 0;
  max-width: 46.971rem;
}

.stack-description {
  color: $light-grey;
  margin-top: 1.618rem;
}

.stack-container {
  display: flex;
  justify-content: space-between;
  margin-top: 4.236rem;
  padding: 0 4.236rem;

  @media (max-width: $max-xsmall) {
    flex-direction: column;
    padding: 0;
  }
}

.stack-list ul {
  @media (max-width: $max-xsmall) {
    display: flex;
    flex-wrap: wrap;
  }
}

// Add space among lists
.stack-container .stack-list + .stack-list ul {
  @media (max-width: $max-xsmall) {
    margin-top: 1.618rem;
  }
}

.stack-list ul > li {
  opacity: 0;
  transform: translate3d(0, 1.618rem, 0);
  transition: all 0.618s ease-out;

  @media (max-width: $max-xsmall) {
    padding: 0.618rem;
  }
}

.stack-list.is-element_revealed ul > li {
  opacity: 1;
  transform: translate3d(0, 0, 0);

  // For each consecutive stack sum the transition delay, starting from
  // a second stack's delay of 0.146.
  @for $i from 1 to 20 {
    & + li:nth-of-type(#{$i}) {
      transition-delay: ($i * 0.146s - 0.146s);
    }
  }
}

// Add vertical space among items
.stack-list ul > li + li {
  @media (min-width: $min-xsmall) {
    margin-top: 1.618rem;
  }
}
