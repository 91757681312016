// Clients layout
// --------------

.clients {
  margin: 11.089rem auto 0;
  max-width: 46.971rem;
}

// [1] Add a negative margin left to make up for the children's one. This way
//     the left is zeroed.
.brands {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -4.236rem; // [1]

  @media (max-width: $max-xsmall) {
    margin-left: 0;
  }
}

// [1] We use Flexbox to handle the wrapping behavior, so we have no control on
//     wrapped item's margins. The workaround we use is to set top and left
//     margins to all items, and make up for them by setting a negative
//     margin-left and a margin-top equal to 0 to their parent container.
.brand {
  align-items: center;
  display: flex;
  height: 4.236rem;
  justify-content: center;
  margin: 4.236rem 0 0 4.236rem; // [1]
  width: 6.854rem;

  // For each consecutive brand sum the transition delay, starting from
  // a second brand's delay of 0.146.
  @for $i from 1 to 20 {
    & + .brand:nth-of-type(#{$i}) {
      transition-delay: ($i * 0.146s - 0.146s);
    }
  }

  @media (max-width: $max-xsmall) {
    margin: 2.618rem 2.618rem 0;
    width: calc((100% * 0.5) - 5.236rem);
  }

  @media (max-width: 320px) {
    margin: 2.618rem 1.618rem 0;
    width: calc((100% * 0.5) - 3.236rem);
  }
}

// Keep brand aspect ratio.
.brand > img {
  max-width: 100%;
  width: auto;
}

.contact_us {
  margin: 6.854rem auto 0;
  max-width: 29.03rem;
  text-align: center;
}

.contact_us p {
  color: $light-grey;
}

.contact_us-link {
  margin-top: 1.618rem;
}
