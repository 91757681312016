// Social icons component
// ----------------------

.social_icon svg {
  fill: $light-grey;
  height: 18px;
  transition: all 0.262s;

  &:hover {
    fill: $white;
    filter: $filter-glow;
  }
}
