// Footer
// ------

.footer {
  align-items: flex-end;
  color: $light-grey;
  display: flex;
  justify-content: space-between;
  margin-top: 11.089rem;
  padding: 1.618rem 2.618rem;
  width: 100%;

  @media (max-width: $max-small) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: $max-small) {
    margin-top: 6.854rem;
  }
}

// Improve text readability for blog templates
.body_blog .footer,
.body_blog .footer p {
  color: $dark-grey;
  font-weight: $weight-medium;
}

// All flex-items occupy the same space
.footer-email,
.footer-copyright,
.footer-social {
  flex: 1;

  // All flex-items are wide as its content
  @media (max-width: $max-small) {
    flex: none;
  }
}

.footer-email {
  font-weight: bold;

  @media (max-width: $max-small) {
    margin-right: 1.618rem;
  }
}

.footer-email-text {
  @media (max-width: $max-medium) {
    display: none;
  }
}

.footer-email-icon {
  display: none;

  @media (max-width: $max-medium) {
    display: inline-block;
  }
}

.footer-email-icon > svg,
.footer-social-icon > svg {
  fill: $light-grey;
  height: 1rem;
  transition: all .262s;

  &:hover {
    fill: $white;
    filter: drop-shadow(0 0 10px rgba(59,179,181,.95));
  }
}

.body_blog .footer-email-icon > svg,
.body_blog .footer-social-icon > svg {
  fill: $dark-grey;
}

.footer-copyright {
  font-weight: $weight-normal;
  text-align: center;

  @media (max-width: $max-medium) {
    flex-grow: 2;
  }

  // [1] Place the copyright to the bottom
  // [2] Let the copyright container wraps
  @media (max-width: $max-small) {
    margin-top: 1.618rem;
    order: 1; // [1]
    width: 100%; // [2]
  }
}

.footer-vat {
  margin-top: 0.382rem;

  @media (max-width: $max-small) {
    margin-top: 0.618rem;
  }
}

.footer-social {
  display: flex;
  font-weight: bold;
  justify-content: flex-end;

  & > * + * {
    margin-left: 1.618rem;
  }
}

.footer-policies {
  margin-top: .382rem;
  font-size: 0.8rem;

  a {
    text-decoration: underline;
  }

  a + a {
    margin-left: .618rem;
  }
}
